import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EnglishIcon from '../../Assets/Icon/english.svg';
import IndonesiaIcon from '../../Assets/Icon/indonesia.svg';
import LanguageIcon from '../../Assets/Icon/language.png';
import LogoImage from '../../Assets/Image/Aps/logo.png';
import CancelIcon from '../../Assets/Image/cancel.svg';
import HamburgerIcon from '../../Assets/Image/hamburger.svg';

function Header({ active }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [navBackground, setNavBackground] = useState(false);

    useEffect(() => {
        const calculateBackground = () => {
            if (window.scrollY > 50) {
                setNavBackground(true);
            } else {
                setNavBackground(false);
            }
        };

        window.addEventListener('scroll', calculateBackground);

        return () => {
            window.removeEventListener('scroll', calculateBackground);
        };
    }, []);

    return (
        <div
            className={`fixed top-0 left-0 w-full transition-all md:transition-none duration-200 z-50 ${open ? 'h-screen overlay' : ''} ${navBackground ? (open ? 'md:bg-white shadow' : 'bg-white shadow') : ''}`}
        >
            <div
                className={`${open ? 'm-4 bg-white rounded p-4 min-h-[95vh]' : 'container'} md:mx-auto py-3 flex flex-col md:flex-row md:justify-between md:items-center`}
            >
                <div className="flex items-center justify-between">
                    <div>
                        <Link to="/aps">
                            <img
                                src={LogoImage}
                                className="h-[35px] md:h-[55px] object-contain rounded-full"
                            />
                        </Link>
                    </div>
                    <div className="md:hidden">
                        {open ? (
                            <button onClick={() => setOpen(false)}>
                                <img
                                    src={CancelIcon}
                                    className="w-[24px] h-[24px]"
                                />
                            </button>
                        ) : (
                            <div className="flex items-center gap-3">
                                <LanguageSelect />
                                <button onClick={() => setOpen(true)}>
                                    <img
                                        src={HamburgerIcon}
                                        className="w-[24px] h-[24px]"
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <ul
                        className={`${open ? 'inline-flex mt-4 font-bold' : 'hidden'} ${navBackground || open ? 'text-slate-700' : 'text-slate-100'} md:inline-flex flex-col md:flex-row gap-4 [&>li]:hover:cursor-pointer text-base`}
                    >
                        <li>
                            <Link
                                className={`hover:text-blue-700 text-base ${active === 'home' ? (navBackground || open ? '!text-blue-800 font-bold' : 'text-white font-bold') : ''}`}
                                onClick={() => (window.location.href = '/')}
                            >
                                {t('aps.navbar.home')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`hover:text-blue-700 text-base ${active === 'catalog' ? (navBackground || open ? '!text-blue-800 font-bold' : 'text-white font-bold') : ''}`}
                                to="/aps/products"
                            >
                                {t('aps.navbar.catalog')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`hover:text-blue-700 text-base ${active === 'about' ? (navBackground || open ? '!text-blue-800 font-bold' : 'text-white font-bold') : ''}`}
                                to="/aps/about-us"
                            >
                                {t('aps.navbar.about')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`hover:text-blue-700 text-base ${active === 'service' ? (navBackground || open ? '!text-blue-800 font-bold' : 'text-white font-bold') : ''}`}
                                to="/aps/service"
                            >
                                {t('aps.navbar.service')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`hover:text-blue-700 text-base ${active === 'partner' ? (navBackground || open ? '!text-blue-800 font-bold' : 'text-white font-bold') : ''}`}
                                to="/aps/partner"
                            >
                                {t('aps.navbar.partner')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={`hover:text-blue-700 text-base ${active === 'contact' ? (navBackground || open ? '!text-blue-800 font-bold' : 'text-white font-bold') : ''}`}
                                to="/aps/contact-us"
                            >
                                {t('aps.navbar.contact')}
                            </Link>
                        </li>
                        <li>
                            <LanguageSelect />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function LanguageSelect() {
    const [languageIcon, setLanguageIcon] = useState(EnglishIcon);
    const { i18n } = useTranslation();

    const handleLanguageChange = (langId, icon) => {
        i18n.changeLanguage(langId);
        setLanguageIcon(icon);
    };

    return (
        <div className="group relative">
            <div className="flex gap-2">
                <img
                    src={LanguageIcon}
                    alt="language-icon"
                    className="h-6 w-6"
                />
                <img
                    src={languageIcon}
                    alt="language-icon"
                    className="h-6 w-6"
                />
            </div>
            <div className="absolute transition-transform duration-100 delay-100 group-hover:block scale-0 group-hover:scale-100 pt-4">
                <ul className="space-y-4 bg-[rgba(255,255,255,0.5)] shadow p-2 rounded">
                    <li
                        onClick={() =>
                            handleLanguageChange('id', IndonesiaIcon)
                        }
                    >
                        <img
                            src={IndonesiaIcon}
                            alt="language-icon"
                            className="h-8 w-8 rounded-full"
                        />
                    </li>
                    <li onClick={() => handleLanguageChange('en', EnglishIcon)}>
                        <img
                            src={EnglishIcon}
                            alt="language-icon"
                            className="h-8 w-8 rounded-full"
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
