import axios from 'axios';
import yaml from 'js-yaml';
import useSWR from 'swr/immutable';

export function useCatalog() {
    const { data, isLoading, error } = useSWR('/catalog.yaml', (url) =>
        axios.get(url).then((response) => response.data),
    );

    const output = {
        data: null,
        isLoading,
        error,
    };

    if (data) {
        try {
            output.data = yaml.load(data);
        } catch (e) {
            output.error = e;
        }
    }

    return output;
}

export function useCatalogRegion() {
    const { data, isLoading, error } = useSWR('/catalog-region.yaml', (url) =>
        axios.get(url).then((response) => response.data),
    );

    const output = {
        data: null,
        isLoading,
        error,
    };

    if (data) {
        try {
            output.data = yaml.load(data);
        } catch (e) {
            output.error = e;
        }
    }

    return output;
}