import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Layout from '../../Layouts';
import AdminContext from '../../Services';

const schema = yup
    .object({
        name: yup.string().required(),
    })
    .required();

function CareersPage() {
    const [type, setType] = useState('Experienced');
    const { loading, getAllMaster, addMasterForm } = useContext(AdminContext);
    const [data, setData] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
        },
    });

    const getData = async () => {
        const newData = await getAllMaster('public/vacancy?type=' + type);
        if (newData) {
            setData(newData);
        }
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    useEffect(() => {
        getData();
    }, [type]);

    const [modal, setModal] = useState({
        show: false,
        id: '',
        type: '',
        data: {},
    });

    const handleClose = () => {
        setModal({ show: false, id: '', type: '', data: {} });
    };

    const handleShow = (id, type, data) =>
        setModal({ show: true, id: id, type: type, data: data });

    const addAction = async (values) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('m_vacancies_id', modal?.data?.id);
        formData.append(
            'document_file',
            values.document_file ? values.document_file[0] : '',
        );

        let response = await addMasterForm('public/vacancy/submit', formData);
        if (!response?.message) {
            getData();
            toast.success('Submit Success');
            handleClose();
        }
    };

    return (
        <Layout>
            <main id="main" className="careers">
                <div className="breadcrumbs bg-career">
                    <div className="container">
                        <h1>{t('careers_title').toUpperCase()}</h1>
                    </div>
                </div>
                <section className="container">
                    <h2 className="mb-3">{t('grow_with_us')}</h2>
                    <div>{t('carrers_description')}</div>
                </section>
                <section className="container text-center">
                    <h2 className="mb-3">{t('job_vacancies')}</h2>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-4">
                            <button
                                className={`btn-all ${type === 'Fresh Graduated' ?? 'active'}`}
                                onClick={() => setType('Fresh Graduated')}
                            >
                                {t('fresh_graduated')}
                            </button>
                        </div>
                        <div className="col-md-4">
                            <button
                                className={`btn-all ${type === 'Experienced' ?? 'active'}`}
                                onClick={() => setType('Experienced')}
                            >
                                {t('experienced')}
                            </button>
                        </div>
                        <div className="col-md-4">
                            <button
                                className={`btn-all ${type === 'Intern' ?? 'active'}`}
                                onClick={() => setType('Intern')}
                            >
                                {t('intership')}
                            </button>
                        </div>
                    </div>
                    <div className="job">
                        <div className="row justify-content-center align-items-center mt-5 mb-3 text-start">
                            <div className="col-12 col-md-3">
                                {t('position')}
                            </div>
                            <div className="col-12 col-md-2">
                                {t('department')}
                            </div>
                            <div className="col-12 col-md-3">
                                {t('placement')}
                            </div>
                            <div className="col-12 col-md-2 text-center">
                                {t('requirements')}
                            </div>
                            <div className="col-12 col-md-2 text-center">
                                {t('join_us')}
                            </div>
                        </div>
                        {data?.map((item, index) => (
                            <div
                                className="row justify-content-center align-items-center mb-2 text-start detail"
                                key={index}
                            >
                                <div className="col-12 col-md-3">
                                    {item?.position}
                                </div>
                                <div className="col-12 col-md-2">
                                    {item?.department}
                                </div>
                                <div className="col-12 col-md-3">
                                    {item?.placement}
                                </div>
                                <div className="col-12 col-md-2 text-center">
                                    <button
                                        className={`btn-submit`}
                                        onClick={() =>
                                            handleShow(item?.id, 'detail', item)
                                        }
                                    >
                                        {t('read_more')}
                                    </button>
                                </div>
                                <div className="col-12 col-md-2 text-center">
                                    <button
                                        className={`btn-submit`}
                                        onClick={() =>
                                            handleShow(item?.id, 'apply', item)
                                        }
                                    >
                                        {t('apply')}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                <Modal
                    show={modal.show && modal.type === 'detail'}
                    onHide={handleClose}
                    size="xl"
                    className="careers"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t('requirements')} {modal?.data?.position}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        className="h5"
                        dangerouslySetInnerHTML={{
                            __html: modal?.data?.description,
                        }}
                    ></Modal.Body>
                    <Modal.Footer>
                        <button className={`btn-submit`} onClick={handleClose}>
                            {t('close')}
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={modal.show && modal.type === 'apply'}
                    onHide={handleClose}
                    size="xl"
                    className="careers"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Apply {modal?.data?.position}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="name"
                                        className="form-label"
                                    >
                                        {t('name')}
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="text"
                                        id="name"
                                        className="form-control"
                                        {...register('name')}
                                        placeholder="Enter full name"
                                    />
                                    <small className="text-danger">
                                        {errors.name?.message}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-3">
                                    <label
                                        htmlFor="document_file"
                                        className="form-label"
                                    >
                                        {t('document_file')}
                                    </label>
                                </div>
                                <div className="col-md-9">
                                    <input
                                        type="file"
                                        id="document_file"
                                        className="form-control"
                                        {...register('document_file')}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className={`btn-submit close`}
                            onClick={handleClose}
                        >
                            {t('close')}
                        </button>
                        <button
                            className={`btn-submit`}
                            onClick={handleSubmit(addAction)}
                        >
                            {t('submit')}
                        </button>
                    </Modal.Footer>
                </Modal>
            </main>
        </Layout>
    );
}

export default CareersPage;
