import { useTranslation } from 'react-i18next';
import BannerImage from '../../Assets/Image/Aps/jumbotron-service.png';
import CompactJumbotron from '../../Components/CompactJumbotron';

function OurServicesJumbotron() {
    const { t } = useTranslation();

    return (
        <CompactJumbotron
            backgroundImage={BannerImage}
            title={t('aps.ourservices_jb.description')}
            description={t('aps.ourservices_jb.description')}
        />
    );
}

export default OurServicesJumbotron;
