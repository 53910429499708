import { t } from 'i18next';
import { useEffect } from 'react';
import Partner1Image from '../../Assets/Image/Home/partner-1.png';
import Partner10Image from '../../Assets/Image/Home/partner-10.png';
import Partner11Image from '../../Assets/Image/Home/partner-11.png';
import Partner12Image from '../../Assets/Image/Home/partner-12.png';
import Partner13Image from '../../Assets/Image/Home/partner-13.png';
import Partner14Image from '../../Assets/Image/Home/partner-14.png';
import Partner2Image from '../../Assets/Image/Home/partner-2.png';
import Partner3Image from '../../Assets/Image/Home/partner-3.png';
import Partner4Image from '../../Assets/Image/Home/partner-4.png';
import Partner5Image from '../../Assets/Image/Home/partner-5.png';
import Partner6Image from '../../Assets/Image/Home/partner-6.png';
import Partner7Image from '../../Assets/Image/Home/partner-7.png';
import Partner8Image from '../../Assets/Image/Home/partner-8.png';
import Partner9Image from '../../Assets/Image/Home/partner-9.png';
import Layout from '../../Layouts';

function PartnersPage() {
    const partner = [
        Partner6Image,
        Partner3Image,
        Partner11Image,
        Partner7Image,
        Partner9Image,
        Partner1Image,
        Partner4Image,
        Partner8Image,
        Partner5Image,
        Partner2Image,
        Partner10Image,
        Partner12Image,
        Partner13Image,
        Partner14Image,
    ];

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <Layout>
            <main id="main" className="about-us">
                <div className="breadcrumbs bg-partners">
                    <div className="container">
                        <h1>{t('partners_title').toUpperCase()}</h1>
                    </div>
                </div>
                <section className="container">
                    <div className="row align-items-center justify-content-center">
                        {partner?.map((item, index) => (
                            <div className="col-6 col-md-4 mb-3" key={index}>
                                <img
                                    src={item}
                                    className="img-fluid"
                                    alt="partner-1"
                                />
                            </div>
                        ))}
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export default PartnersPage;
