import { useEffect, useState } from 'react';

function TailwindLoader({ children }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        import('../tailwind.css')
            .then(() => {
                setLoading(false);
            })
            .catch(() => {});
    }, []);

    if (loading) {
        return (
            <div
                style={{
                    minWidth: '100vw',
                    minHeight: '100vh',
                    background: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                LOADING
            </div>
        );
    }

    return children;
}

export default TailwindLoader;
