import ScrollToTop from '../../Components/ScrollToTop';
import TailwindLoader from '../../Components/TailwindLoader';
import Footer from '../../Layouts/Aps/Footer';
import Header from '../../Layouts/Aps/Header';
import OurServicesJumbotron from '../../Layouts/Aps/OurServicesJumbotron';
import ServicesDetail from '../../Layouts/Aps/ServicesDetail';

function ApsServicePage() {
    return (
        <TailwindLoader>
            <Header active="service" />
            <OurServicesJumbotron />
            <ServicesDetail />
            <Footer />

            <ScrollToTop />
        </TailwindLoader>
    );
}

export default ApsServicePage;
