import { t } from 'i18next';
import { useEffect } from 'react';
import AboutUsComponent from '../../Components/aboutUs';
import Layout from '../../Layouts';

function OurServicesPage() {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <Layout>
            <main id="main" className="about-us">
                <div className="breadcrumbs bg-our-services">
                    <div className="container">
                        <h1>{t('our_services_title').toUpperCase()}</h1>
                    </div>
                </div>
                <AboutUsComponent showTitle={false} />
            </main>
        </Layout>
    );
}

export default OurServicesPage;
