import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FooterImage from '../../Assets/Image/Aps/footer.png';
import LogoImage from '../../Assets/Image/Aps/logo.png';

function GetInTouch() {
    const { t } = useTranslation();

    return (
        <div className="max-w-[85%] group lg:max-w-[70%] mx-auto bg-slate-100 flex flex-col md:flex-row items-center gap-5 -translate-y-[50%] p-5">
            <div>
                <h1 className="not-italic text-center md:!text-left !leading-tight font-semibold text-3xl md:text-5xl">
                    {t('aps.footer.get_in_touch')}
                </h1>
            </div>
            <div className="space-y-2">
                <p className="font-light text-base text-center md:!text-left md:text-lg">
                    {t('aps.footer.contact_description')}
                </p>
                <a
                    href="mailto:info@thepasgroup.co.id"
                    className="flex flex-col items-center md:items-start hover:text-blue-900 hover:cursor-pointer text-blue-900 group-hover:animate-pulse"
                >
                    {t('aps.footer.contact_us')}
                    <div className="w-[30%] h-2 bg-blue-900 transition-all duration-300 delay-200 group-hover:w-full"></div>
                </a>
            </div>
        </div>
    );
}

function Footer({ withGetInTouch }) {
    const { t } = useTranslation();

    withGetInTouch = useMemo(
        () => (withGetInTouch === undefined ? true : withGetInTouch),
        [withGetInTouch],
    );

    console.log(withGetInTouch);

    return (
        <div className="mt-4">
            {withGetInTouch && (
                <div className="h-[350px]">
                    <img src={FooterImage} className="size-full object-cover" />
                </div>
            )}
            <div className="container space-y-5">
                {withGetInTouch && <GetInTouch />}
                <div className="grid grid-cols-1 md:grid-cols-11 gap-4 pb-20">
                    <div className="md:col-span-2">
                        <img
                            src={LogoImage}
                            className="object-contain h-[70px]"
                        />
                        <p className="text-sm font-light">
                            {t('aps.footer.slogan')}
                        </p>
                    </div>
                    <div className="md:col-span-2">
                        <h3 className="font-bold text-sm">MENU</h3>
                        <ul className="space-y-2 mt-2">
                            <li>
                                <Link
                                    className="text-sm font-light hover:text-blue-900"
                                    to="/aps"
                                >
                                    {t('aps.navbar.home')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-sm font-light hover:text-blue-900"
                                    to="/aps/about-us"
                                >
                                    {t('aps.navbar.about')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-sm font-light hover:text-blue-900"
                                    to="/aps/service"
                                >
                                    {t('aps.navbar.service')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-sm font-light hover:text-blue-900"
                                    to="/aps/partner"
                                >
                                    {t('aps.navbar.partner')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="text-sm font-light hover:text-blue-900"
                                    to="/aps/contact-us"
                                >
                                    {t('aps.navbar.contact')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="md:col-span-3 lg:col-span-4 flex flex-col gap-2">
                        <h3 className="font-bold">
                            {t('aps.footer.contact_title')}
                        </h3>
                        <span className="text-base md:text-xl !font-mono font-bold">
                            +62 21-270 982 44
                        </span>
                        <a
                            href="mailto:aps@thepasgroup.co.id"
                            className="text-base md:text-xl !font-mono font-bold"
                        >
                            aps@thepasgroup.co.id
                        </a>
                    </div>
                    <div className="md:col-span-4 lg:col-span-3">
                        <h3 className="font-bold mb-3">
                            {t('aps.footer.address_title')}
                        </h3>
                        <div className="space-y-2">
                            <div>
                                <h4 className="text-base md:text-lg font-bold text-blue-900">
                                    Head Office Balikpapan
                                </h4>
                                <p className="text-sm md:text-base text-slate-600">
                                    Jl. Ketinjau Blok D3 No. 20b, Balikpapan
                                    Selatan Gn. Bahagia, Kecamatan Balikpapan
                                    Selatan, Kota Balikpapan,
                                    Kalimantan Timur 76114
                                </p>
                            </div>
                            <div>
                                <h4 className="text-base md:text-lg font-bold text-blue-900">
                                    Branch Office Cilegon
                                </h4>
                                <p className="text-sm md:text-base text-slate-600">
                                    Jl. Komplek Arga Baja Pura, Jalan Arga Raung
                                    Blok B3 No 31 Kel. Grogol Kec. Grogol,
                                    Cilegon, Banten. 42436
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
