import axios from 'axios';

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
});

API.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        const { status } = err.response;
        if (status === 401) {
            localStorage.clear();
            window.location = '/login';
        }
        return Promise.reject(err);
    },
);

export default API;
