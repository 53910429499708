import Footer from './Components/Footer';
import Header from './Components/Header';

function Layout(props) {
    return (
        <div>
            <Header />
            {props?.children}
            {!props?.footer && <Footer />}
        </div>
    );
}

export default Layout;
