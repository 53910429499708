import { t } from 'i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../../../Assets/Icon/arrow-right.svg';

function NewsCard(props) {
    return (
        <div className="col-12 col-md-6 col-lg-4 mb-4" key={props?.index}>
            <div className="news-card">
                <Link to={`/news/${props?.item?.id}`}>
                    <img
                        src={
                            process.env.REACT_APP_FILE_URL +
                            props?.item?.photo_file
                        }
                        className="news-img"
                        alt="news"
                    />
                </Link>
                <h4>{props?.item?.name}</h4>
                <h5>{props?.item?.short_description}</h5>
                <span className="small text-secondary">
                    {moment(props?.item?.created_at).format(
                        'dddd, DD MMMM YYYY',
                    )}
                </span>
                <div className="link">
                    <Link to={`/news/${props?.item?.id}`}>
                        <span>{t('view_detail_title')}</span>
                        <img src={ArrowRightIcon} alt="" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NewsCard;
