import { useTranslation } from 'react-i18next';
import BannerImage from '../../Assets/Image/Aps/jumbotron-about.png';
import CompactJumbotron from '../../Components/CompactJumbotron';

function AboutUsJumbotron() {
    const { t } = useTranslation();

    return (
        <CompactJumbotron
            backgroundImage={BannerImage}
            title={t('aps.aboutus_jb.title')}
            description={t('aps.aboutus_jb.description')}
        />
    );
}

export default AboutUsJumbotron;
