import ScrollToTop from '../../Components/ScrollToTop';
import TailwindLoader from '../../Components/TailwindLoader';
import ContactUs from '../../Layouts/Aps/ContactUs';
import ContactUsJumbotron from '../../Layouts/Aps/ContactUsJumbotron';
import Footer from '../../Layouts/Aps/Footer';
import Header from '../../Layouts/Aps/Header';

function ApsContactUsPage() {
    return (
        <TailwindLoader>
            <Header active="contact" />
            <ContactUsJumbotron />
            <ContactUs />
            {/* <CompactFooter /> */}
            <Footer withGetInTouch={false} />

            <ScrollToTop />
        </TailwindLoader>
    );
}

export default ApsContactUsPage;
