import { useReducer } from 'react';
import { toast } from 'react-toastify';
import ApiWithoutAuth from '../Config/Api/ApiWithoutAuth';
import AssetContext from './index';
import AssetReducer from './reducer';
import { ADD, GET, GET_ALL } from './types';

const MasterState = ({ children }) => {
    const initialState = {
        data: [],
        loading: true,
    };

    const [state, dispatch] = useReducer(AssetReducer, initialState);

    const getAllMaster = async (path, parameter) => {
        try {
            const data = await ApiWithoutAuth.getDataWithoutAuth(
                `/${path}`,
                parameter,
            );
            if (data?.status === 200) {
                dispatch({ type: GET_ALL, payload: data?.data });
            } else {
                toast.error(JSON.stringify(data?.data?.message));
            }
            return data?.data;
        } catch (err) {
            console.error(err.message);
        }
    };

    const getMaster = async (path, id) => {
        try {
            const data = await ApiWithoutAuth.getDataWithoutAuth(
                `/${path}/${id}`,
            );
            if (data?.status === 200) {
                dispatch({ type: GET, payload: data?.data });
            } else {
                toast.error(JSON.stringify(data?.data?.message));
            }
            return data?.data;
        } catch (err) {
            console.error(err.message);
        }
    };

    const addMasterForm = async (path, body) => {
        try {
            const data = await ApiWithoutAuth.postDataFormWithoutAuth(
                `/${path}`,
                body,
            );

            if (data?.status === 200) {
                dispatch({ type: ADD });
            } else {
                toast.error(JSON.stringify(data?.data?.message));
            }
            return data?.data;
        } catch (err) {
            console.error(err.message);
        }
    };

    const { data, loading } = state;

    return (
        <AssetContext.Provider
            value={{
                data,
                loading,
                getAllMaster,
                getMaster,
                addMasterForm,
            }}
        >
            {children}
        </AssetContext.Provider>
    );
};

export default MasterState;
