import {
    GoogleMap,
    InfoWindow,
    Marker,
    useLoadScript,
} from '@react-google-maps/api';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import FacebookIcon from '../../Assets/Icon/facebook.png';
import InstagramIcon from '../../Assets/Icon/instagram.png';
import LinkedinIcon from '../../Assets/Icon/linkedin-logo.png';
import WhatsappIcon from '../../Assets/Icon/whatsapp.png';
import Layout from '../../Layouts';

function ContactUsPage() {
    const center = useMemo(() => ({ lat: -1.871221, lng: 116.07537 }));

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const [mapRef, setMapRef] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const markers = [
        {
            name: t('head_office_jakarta'),
            address:
                'Wisma Iskandarsyah Blok A4-A5, Jl. Iskandarsyah Raya Kav.12-14, Melawai, Kebayoran Baru, Jakarta Selatan, DKI Jakarta. 12160',
            lat: -6.245663,
            lng: 106.8051004,
        },
        {
            name: t('branch_office_cilegon'),
            address:
                'Komplek Arga Baja Pura, Jl. Jalan Arga Raung Blok B3 No. 31, Grogol, Gerogol, Cilegon, Banten. 42436',
            lat: -5.98806,
            lng: 106.0278131,
        },
        {
            name: t('branch_office_samarinda'),
            address:
                'Jl. Haji Marhusin No.16, Sungai Kapih, Sambutan, Samarinda, Kalimantan Timur. 75115',
            lat: -0.527212,
            lng: 117.1616374,
        },
        {
            name: t('branch_office_balikpapan'),
            address:
                'Jl. Syarifuddin Yoes No 60, Sepinggan, Balikpapan Selatan, Balikpapan, Kalimantan Timur. 76115',
            lat: -1.2451333,
            lng: 116.890866,
        },
        {
            name: t('branch_office_bali'),
            address:
                'Pelabuhan Benoa, Zona Marina, Pedungan, Denpasar Selatan, Kota Denpasar, Bali. 80222',
            lat: -8.7431386,
            lng: 115.2092273,
        },
    ];

    const handleMarkerClick = (id, lat, lng, name, address) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, name, address });
        setIsOpen(true);
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <Layout footer={true}>
            <main id="main" className="contact-us">
                <div className="breadcrumbs bg-contact-us">
                    <div className="container">
                        <h1>{t('contact_us_sub_title').toUpperCase()}</h1>
                    </div>
                </div>
                <section className="container my-0 text-center contact-us-page">
                    <div className="mb-5 pb-5">
                        <h1>{t('get_in_touch')}</h1>
                    </div>
                    <div className="mt-5">
                        <h2>{t('contact_title')}</h2>
                        <div className="ms-auto me-auto ms-md-0 me-md-0">
                            +62 21-270 982 44
                        </div>
                        <div className="ms-auto me-auto ms-md-0 me-md-0">
                            INFO@THEPASGROUP.CO.ID
                        </div>
                    </div>
                    <div className="social-link text-center text-md-right ms-auto me-auto pb-2">
                        <a href="https://whatsapp.com/">
                            <img src={WhatsappIcon} alt="whatsapp" />
                        </a>
                        <a href="https://instagram.com/">
                            <img src={InstagramIcon} alt="instagram" />
                        </a>
                        <a href="https://facebook.com/">
                            <img src={FacebookIcon} alt="facebook" />
                        </a>
                        <a href="https://linkedin.com/">
                            <img src={LinkedinIcon} alt="linkedin" />
                        </a>
                    </div>
                    <div className="footer-links text-white">
                        <h2>{t('address_title').toUpperCase()}</h2>
                        {markers?.map((item) => (
                            <div className="mt-2 mb-1">
                                <div className="fw-bold">{item?.name}</div>
                                <div className="fw-normal">{item?.address}</div>
                            </div>
                        ))}
                    </div>
                    <div className="maps">
                        {!isLoaded ? (
                            <h1>Loading...</h1>
                        ) : (
                            <GoogleMap
                                mapContainerClassName="map-container"
                                center={center}
                                zoom={5}
                            >
                                {markers.map(
                                    ({ name, address, lat, lng }, ind) => (
                                        <Marker
                                            key={ind}
                                            position={{ lat, lng }}
                                            onClick={() => {
                                                handleMarkerClick(
                                                    ind,
                                                    lat,
                                                    lng,
                                                    name,
                                                    address,
                                                );
                                            }}
                                        >
                                            {isOpen &&
                                                infoWindowData?.id === ind && (
                                                    <InfoWindow
                                                        onCloseClick={() => {
                                                            setIsOpen(false);
                                                        }}
                                                    >
                                                        <div className="info-maps">
                                                            <h4>
                                                                {
                                                                    infoWindowData.name
                                                                }
                                                            </h4>
                                                            <div>
                                                                {
                                                                    infoWindowData.address
                                                                }
                                                            </div>
                                                        </div>
                                                    </InfoWindow>
                                                )}
                                        </Marker>
                                    ),
                                )}
                            </GoogleMap>
                        )}
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export default ContactUsPage;
