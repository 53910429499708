import BannerImage from '../Assets/Image/product-bg.png';

import LogoImage from '../Assets/Image/logo-4k.png';

import Product1Image from '../Assets/Image/product-1.png';
import Product2Image from '../Assets/Image/product-2.png';
import Product3Image from '../Assets/Image/product-3.png';

function ProductShowcase() {
    const products = [
        {
            title: 'TRADING',
            description: ' Trusted Partner in Quality Construction Materials',
            target: '/pasniaga',
            banner: Product1Image,
        },
        {
            title: 'SHIP AGENCY',
            description:
                'Dedicated parnters, ensuring every voyage is seamless and secure',
            target: '/psn',
            banner: Product2Image,
        },
        {
            title: 'SHIP PROCUREMENT',
            description:
                'Provide  solutions to support the safety and optimal performance of your ship',
            target: '/aps',
            banner: Product3Image,
        },
    ];

    return (
        <div className="ps-container">
            <img src={BannerImage} className="ps-bg-image" />

            <div className="container ps-logo">
                <img src={LogoImage} />
            </div>

            <div className="container ps-subcontainer">
                {products.map((product, index) => (
                    <a href={product.target} className="ps-item" key={index}>
                        <div className="ps-read-more-container">READ MORE</div>
                        <img src={product.banner} className="ps-item-bg" />

                        <div className="item-container grid-cols-3">
                            <div className="wrapper">
                                <h2>{product.title}</h2>
                                <p>{product.description}</p>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default ProductShowcase;
