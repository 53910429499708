import { t } from 'i18next';
import { useEffect } from 'react';
import AboutUsImage from '../../Assets/Image/Home/about-us.png';
import AboutUsComponent from '../../Components/aboutUs';
import Partners from '../../Components/partners';
import Layout from '../../Layouts';

function HomePage(params) {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <Layout>
            <section
                id="hero"
                className="d-flex justify-content-center align-items-center"
            >
                <div
                    className="container position-relative"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                >
                    <h1>{t('slogan')}</h1>
                    <div className="description">{t('slogan_description')}</div>
                </div>
            </section>

            <main id="main">
                <section className="container text-center">
                    <h1 className="mb-3">
                        {t('about_us_title').toUpperCase()}
                    </h1>
                    <h4>{t('about_us_home_first')}</h4>
                    <div className="my-4">
                        <img
                            src={AboutUsImage}
                            className="img-fluid"
                            alt="about-us"
                        />
                    </div>
                    <div className="">{t('about_us_home_second')}</div>
                </section>
                <AboutUsComponent showTitle={true} />
                <section className="container text-center">
                    <h1 className="mb-3">
                        {t('our_partners_title').toUpperCase()}
                    </h1>
                    <Partners />
                </section>
                <div className="footer-line"></div>
            </main>
        </Layout>
    );
}

export default HomePage;
