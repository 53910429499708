import { Trans, useTranslation } from 'react-i18next';
import ValuesImage from '../../Assets/Image/Aps/values.png';

function OurValues() {
    const { t } = useTranslation();

    return (
        <div className="container py-32">
            <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="md:col-span-8">
                    <div className="md:max-w-[80%] lg:max-w-[60%] space-y-4">
                        <p className="font-light md:text-4xl lg:text-5xl">
                            <Trans
                                i18nKey="aps.ourvalues.title"
                                components={{
                                    highlight: (
                                        <span className="font-semibold text-blue-900 md:text-4xl lg:text-5xl" />
                                    ),
                                }}
                            />
                        </p>
                        <p className="font-light text-base md:text-2xl">
                            {t('aps.ourvalues.description')}
                        </p>
                        <div>
                            <span className="font-semibold text-xl text-blue-900">
                                {t('aps.ourvalues.label')}
                            </span>
                            <div className="h-[8px] w-[40%] bg-blue-900"></div>
                        </div>
                    </div>
                    <div className="flex mt-16 mb-0 md:!mb-16">
                        <div className="flex flex-col gap-1 md:gap-3 justify-center w-[130px] h-[130px] md:w-[180px] md:h-[180px] lg:w-[250px] lg:h-[250px] bg-blue-900 text-white p-2 md:!p-4">
                            <h3 className="text-base md:text-3xl">
                                {t('aps.ourvalues.1_title')}
                            </h3>
                            <p className="text-xs md:text-base font-light">
                                {t('aps.ourvalues.1_description')}
                            </p>
                        </div>
                        <div className="flex flex-col gap-1 md:gap-3 justify-center w-[130px] h-[130px] md:w-[180px] md:h-[180px] lg:w-[250px] lg:h-[250px] bg-white p-2 md:!p-4">
                            <h3 className="text-base md:text-3xl">
                                {t('aps.ourvalues.2_title')}
                            </h3>
                            <p className="text-xs md:text-base font-light">
                                {t('aps.ourvalues.2_description')}
                            </p>
                        </div>
                        <div className="flex flex-col gap-1 md:gap-3 justify-center w-[130px] h-[130px] md:w-[180px] md:h-[180px] lg:w-[250px] lg:h-[250px] bg-green-100 text-blue-900 p-2 md:!p-4">
                            <h3 className="text-base md:text-3xl">
                                {t('aps.ourvalues.3_title')}
                            </h3>
                            <p className="text-xs md:text-base font-light">
                                {t('aps.ourvalues.3_description')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex items-end md:col-span-4">
                    <div className="w-full h-[300px] md:h-[600px]">
                        <img
                            src={ValuesImage}
                            className="object-cover size-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurValues;
