import { useEffect } from 'react';
import ProductShowcase from '../../Components/ProductShowCase';
import RootFooter from '../../Layouts/Components/RootFooter';
import RootHeader from '../../Layouts/Components/RootHeader';

function RootPage(params) {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <>
            <RootHeader />
            <main id="main">
                <ProductShowcase />

                {/* <section className="container text-center">
          <h1 className="mb-3">{t("our_partners_title").toUpperCase()}</h1>
          <Partners />
        </section> */}
            </main>
            <RootFooter />
        </>
    );
}

export default RootPage;
