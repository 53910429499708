import API from './ApiConfig';

function buildResponse(response) {
    return response;
}

const ApiWithoutAuth = {
    postDataWithoutAuth: async (url, body) => {
        return API.post(`${url}`, body)
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
    getDataWithoutAuth: async (url, parameter) => {
        return API.get(`${url}`, {
            params: parameter,
        })
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
    postDataFormWithoutAuth: async (url, body) => {
        return API.post(`${url}`, body, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => buildResponse(response))
            .catch((err) => buildResponse(err.response ?? null));
    },
};

export default ApiWithoutAuth;
