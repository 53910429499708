import ScrollToTop from '../../Components/ScrollToTop';
import TailwindLoader from '../../Components/TailwindLoader';
import Footer from '../../Layouts/Aps/Footer';
import Header from '../../Layouts/Aps/Header';
import PartnerJumbotron from '../../Layouts/Aps/PartnerJumbotron';
import Partners from '../../Layouts/Aps/Partners';

function ApsPartnerPage() {
    return (
        <TailwindLoader>
            <Header active="partner" />
            <PartnerJumbotron />
            <div className="md:py-10">
                <Partners />
            </div>
            <Footer />

            <ScrollToTop />
        </TailwindLoader>
    );
}

export default ApsPartnerPage;
