import { useTranslation } from 'react-i18next';
import BannerImage from '../../Assets/Image/Aps/contact.png';

function ContactUs() {
    const { t } = useTranslation();

    return (
        <div className="container py-20">
            <div className="grid md:grid-cols-2 gap-8 max-w-[80%] mx-auto">
                <div className="md:order-2">
                    <img src={BannerImage} className="size-full object-cover" />
                </div>
                <div className="flex flex-col gap-3 justify-center items-center md:items-start">
                    <p className="font-light text-center md:!text-left !leading-relaxed text-xl md:text-2xl lg:text-3xl">
                        {t('aps.contactus.description')}
                    </p>
                    <div>
                        <a
                            href="mailto:info@thepasgroup.co.id"
                            className="px-6 py-2 font-semibold border-2 transition-colors border-blue-600 text-blue-700 hover:text-white hover:bg-blue-500"
                        >
                            {t('aps.contactus.contact_us')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
