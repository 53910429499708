import TailwindLoader from '../../Components/TailwindLoader';
import Footer from '../../Layouts/Aps/Footer';
import Header from '../../Layouts/Aps/Header';

import ScrollToTop from '../../Components/ScrollToTop';
import AboutUsJumbotron from '../../Layouts/Aps/AboutUsJumbotron';
import Hopes from '../../Layouts/Aps/Hopes';
import Mission from '../../Layouts/Aps/Mission';

function ApsAboutUsPage() {
    return (
        <TailwindLoader>
            <Header active="about" />
            <AboutUsJumbotron />
            <Mission />
            <Hopes />
            {/* <Teams /> */}
            <Footer />

            <ScrollToTop />
        </TailwindLoader>
    );
}

export default ApsAboutUsPage;
