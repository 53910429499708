import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ChinaIcon from '../../Assets/Icon/china.svg';
import EnglishIcon from '../../Assets/Icon/english.svg';
import IndonesiaIcon from '../../Assets/Icon/indonesia.svg';
import JapanIcon from '../../Assets/Icon/japan.svg';
import LanguageIcon from '../../Assets/Icon/language.png';
import ListIcon from '../../Assets/Icon/list.svg';
import XIcon from '../../Assets/Icon/x.svg';
import LogoImage from '../../Assets/Image/logo.png';

function Header(props) {
    const { t, i18n } = useTranslation();
    const [isMobile, setIsmobile] = useState(false);
    const [languageIcon, setLanguageIcon] = useState(EnglishIcon);
    const pathname = window.location.pathname;

    const handleLanguageChange = (e, icon) => {
        i18n.changeLanguage(e);
        setLanguageIcon(icon);
    };

    return (
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center">
                <Link to="/" className="logo me-auto">
                    <img src={LogoImage} alt="" className="img-fluid" />
                </Link>

                <nav
                    id="navbar"
                    className={`navbar order-last order-lg-0 ${
                        isMobile ? 'navbar-mobile' : ''
                    }`}
                >
                    <ul>
                        <li>
                            <Link
                                className={pathname === '/' ? 'active' : ''}
                                to="/"
                            >
                                {t('home_title').toUpperCase()}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    pathname === '/psn/about-us' ? 'active' : ''
                                }
                                to="/psn/about-us"
                            >
                                {t('about_us_title').toUpperCase()}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    pathname === '/psn/our-services'
                                        ? 'active'
                                        : ''
                                }
                                to="/psn/our-services"
                            >
                                {t('our_services_title').toUpperCase()}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    pathname === '/psn/news' ? 'active' : ''
                                }
                                to="/psn/news"
                            >
                                {t('news_title').toUpperCase()}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    pathname === '/psn/partners' ? 'active' : ''
                                }
                                to="/psn/partners"
                            >
                                {t('partners_title').toUpperCase()}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    pathname === '/psn/careers' ? 'active' : ''
                                }
                                to="/psn/careers"
                            >
                                {t('careers_title').toUpperCase()}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className={
                                    pathname === '/psn/contact-us'
                                        ? 'active'
                                        : ''
                                }
                                to="/psn/contact-us"
                            >
                                {t('contact_us_title').toUpperCase()}
                            </Link>
                        </li>
                    </ul>
                    <i
                        className="mobile-nav-toggle ms-3"
                        onClick={() => setIsmobile(isMobile ? false : true)}
                    >
                        <img
                            src={isMobile ? XIcon : ListIcon}
                            alt="icon-list"
                        />
                    </i>
                </nav>

                <div className="navbar">
                    <div className="dropdown language">
                        <div className="icon">
                            <img
                                src={LanguageIcon}
                                alt="language"
                                className="icon-selector"
                            />
                            <img
                                src={languageIcon}
                                alt="language-selected"
                                className="icon-flag"
                            />
                        </div>
                        <ul>
                            <li>
                                <img
                                    src={IndonesiaIcon}
                                    onClick={() =>
                                        handleLanguageChange(
                                            'id',
                                            IndonesiaIcon,
                                        )
                                    }
                                    alt="language-indonesia"
                                />
                            </li>
                            <li>
                                <img
                                    src={EnglishIcon}
                                    onClick={() =>
                                        handleLanguageChange('en', EnglishIcon)
                                    }
                                    alt="language-english"
                                />
                            </li>
                            <li>
                                <img
                                    src={JapanIcon}
                                    onClick={() =>
                                        handleLanguageChange('jp', JapanIcon)
                                    }
                                    alt="language-japan"
                                />
                            </li>
                            <li>
                                <img
                                    src={ChinaIcon}
                                    onClick={() =>
                                        handleLanguageChange('cn', ChinaIcon)
                                    }
                                    alt="language-china"
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
